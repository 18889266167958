// Component: UI Loader
// ========================================================================

@import '../../scss/base/bootstrap-extended/include';
@import '../../scss/base/components/include';

.ui-loader {
  position: relative;

  &.show {

    // background-color: rgba($color: $black, $alpha: 0.3);
    .overlay {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
      border-radius: 8px;
    }

    .overlay-sideCard {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
      border-radius: 8px;
    }

    > :first-child,
    code {
      background-color: initial;
    }
  }

  #register-loader {
    display: flex;
    align-items: center; // Cela centre verticalement les enfants
    justify-content: center; // Cela centre horizontalement les enfants
    height: 100%;
  }


  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    /* Supprimez les marges, elles ne sont pas nécessaires avec cette méthode */
    /* Utilisez transform pour ajuster précisément l'élément */
    transform: translate(-50%, -50%);
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  #loader-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 50%;
  }

}